<template>
  <transition
    appear
    :name="transitionName"
    :mode="transitionMode"
    :enter-active-class="transitionEnterActiveClass"
    @before-leave="handleBeforeLeave"
    @enter="handleEnter"
    @after-enter="afterEnter"
  >
    <slot />
  </transition>
</template>

<script>
const DEFAULT_TRANSITION = "fade";
const DEFAULT_TRANSITION_MODE = "out-in";

export default {
  name: "TransitionPage",
  data() {
    return {
      transitionName: DEFAULT_TRANSITION,
      transitionMode: DEFAULT_TRANSITION_MODE,
      transitionEnterActiveClass: ""
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      const transitionName = this.getTransitionName(to, from);
      this.transitionMode = DEFAULT_TRANSITION_MODE;
      this.transitionEnterActiveClass = `${transitionName}-enter-active`;
      this.$store.commit("setBackgroundVideo", !!to.meta.backgroundVideo);
      this.transitionName = transitionName;
      next();
    });
  },
  methods: {
    getTransitionName(to, from) {
      const transitionName = to.meta.transitionName || from.meta.transitionName || DEFAULT_TRANSITION;
      if (transitionName === "slide") {
        const toDepth = to.meta.index || 0;
        const fromDepth = from.meta.index || 0;
        return toDepth < fromDepth ? "slide-right" : "slide-left";
      }
      return transitionName;
    },
    handleEnter(el, done) {
      // Define the enter transition logic here
      done();
    },
    handleBeforeLeave(el) {
      // Define the before leave transition logic here
    },
    afterEnter(el) {
      // Define the after enter transition logic here
    }
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active,
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active,
.zoom-enter-active,
.zoom-leave-active,
.zoomOut-SlideIn-enter-active,
.zoomOut-SlideIn-leave-active,
.zoomIn-SlideOut-enter-active,
.zoomIn-SlideOut-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.fade-enter,
.fade-leave-active,
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(100vw, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  transform: translate(-100vw, 0);
}

.zoomOut-SlideIn-enter {
  transform: translate(0, 100vh);
}

.zoomIn-SlideOut-leave-active {
  transform: translate(0, 100vh);
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
</style>
