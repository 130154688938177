<template>
  <div class="main-wrap-home">
    <div class="wrap-l" v-if="!loading">
      <div class="card-t-l">
        <v-card color="#fff" class="avances a-r card-rounded" elevation="0" height="100%">
          <v-card-title>
            <v-row>
              <v-col cols="6">
                Plan de inversión
              </v-col>
              <v-col cols="6">
                <v-select outlined rounded label="Unidades" hide-details dense :items="unidades" v-model="uni"
                  @change="unidadGet">
                </v-select>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-subtitle>
            Se visualiza el avance por año
          </v-card-subtitle>
          <v-card-text class="linea-proyec">
            <v-sparkline :fill="fill" :gradient="selectedGradient" :line-width="width" :padding="padding"
              :smooth="radius || false" :value="value" auto-draw :labels="labels">
            </v-sparkline>
          </v-card-text>
        </v-card>

        <v-card scrollable color="#fff" class="avances a-l card-rounded" height="100%" elevation="0">
          <v-card-title>{{ uni == 'all' ? 'Todas las unidades' : uni }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="wrap-proyectos-list">
            <v-list dense>
              <v-list-item two-line v-for="(x, i) in findProyects" :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ x.titulo }}</v-list-item-title>
                  <v-list-item-subtitle class="text-caption-v2">{{ x.descripcion }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ x.presupuestoInicial | formatterAmount }}
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="investment-total">
              <span class="investment-title">Total de inversión</span>
              <span> {{ mainCalcule.costoTotal | formatterAmount }} </span>
              <v-progress-linear :value="((mainCalcule.costoTotal / 1000000) * 100)+1" height="8px" rounded color="primary"></v-progress-linear>
            </div>
          </v-card-actions>
        </v-card>
      </div>
      <div class="card-b-l">
        <v-card color="#fff" class="card-rounded shadow card" elevation="0" style="    overflow: clip;">
          <v-card-title>
            Departamentos
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="scrollable-content">
            <v-list dense>
              <v-list-item v-for="(x, i) in departamentosAll" :key="i">
                <v-list-item-content>
                  <v-list-item-title class="department-title">
                    <b>{{ x.nombre }}</b>
                    <v-chip color="primary" outlined small> {{ x.cantidad }}</v-chip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-card color="#fff" class="mapa-container card-rounded" elevation="0" @click="$router.push('/proyects')">
          <div class="map-wrapper">
            <maps :staticMap="true" :controlsShow="false" :pointsMarket="pointsProyects" :poligons="polygonsProyects"
              :lines="linesProyects" :allProyectsLocal="allProyectsLocal" class="map-content" />
          </div>
        </v-card>
      </div>
    </div>
    <div class="wrap-r" v-if="!loading">
      <v-card class="shadow card-list-calculate card-rounded" width="100%" color="#FAFAFA" elevation="0">
        <div class="card-money">
          <h2 class="investment-amount">980,000</h2>
          <p class="investment-subtitle">Inversión social</p>
          <p class="investment-description">Familias subsidiadas</p>
          <v-icon x-large color="primary" class="investment-icon">mdi-human-male-female-child</v-icon>
        </div>
      </v-card>
      <v-card color="#fff" class="shadow card-list-calculate card-rounded" elevation="0">
        <div class="card-money">
          <h2 class="investment-amount">{{ mainCalcule.presupuestoInicial | formatterAmount }}</h2>
          <p>Presupuesto Inicial</p>
        </div>
      </v-card>
      <v-card dark color="success" class="shadow card-list-calculate card-rounded" elevation="0">
        <div class="card-money">
          <h2 class="investment-amount" style="color: #fff !important;">{{ mainCalcule.presupuestoInvertido |
            formatterAmount }}</h2>
          <p class="investment-description" style="color: #fff !important;">Presupuesto Invertido</p>
          <v-icon size="50px" class="investment-icon">mdi-trending-up</v-icon>
        </div>
      </v-card>
      <div class="wrap-empleos">
        <v-card dark color="#fff" class="shadow card-list-calculate card-rounded" elevation="0">
          <div class="card-money">
            <h2 class="investment-amount">{{ mainCalcule.productosSubcontratados | currencySinL }}</h2>
            <p>Productos <br> subcontratados</p>
          </div>
        </v-card>
        <v-card dark color="#fff" class="shadow card-list-calculate card-rounded" elevation="0">
          <div class="card-money">
            <h2 class="investment-amount">{{ mainCalcule.empleosGenerados | currencySinL }}</h2>
            <p>Empleos a generar</p>
          </div>
        </v-card>
      </div>
      <v-card elevation="0" class="shadow card-list-calculate card-rounded">
        <div class="card-money">
          <h2 class="investment-amount">{{ mainCalcule.comunidadesBeneficiarias | currencySinL }}</h2>
          <p>Comunidades Beneficiarias</p>
          <v-icon x-large color="primary" class="investment-icon">mdi-home-group</v-icon>
        </div>
      </v-card>
      <v-card class="shadow card-list-calculate card-rounded" width="100%" color="#FAFAFA" elevation="0">
        <div class="card-money">
          <h2 class="investment-amount">{{ mainCalcule.familiasBeneficiarias | currencySinL }}</h2>
          <p>Familias Beneficiarias</p>
          <v-icon x-large color="primary" class="investment-icon">mdi-human-male-female-child</v-icon>
        </div>
      </v-card>
    </div>
    <div class="loading-home" v-if="loading">
      <img src="@/assets/campaña.svg" width="200px" alt="logo" class="logo-loading">
      <v-progress-circular indeterminate color="#62c3db"></v-progress-circular>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import maps from '@/components/mapa.vue'
import { env } from "@/environment/env";
const gradients = [
  ['#62c3db'],
  ['#62c3db'],
  ['red', 'orange', 'yellow'],
  ['#62c3db', 'violet'],
  ['#62c3db', '#62c3db00'],
  ['#f72047', '#ffd200', '#1feaea'],
]
export default {
  name: 'Home',

  components: {
    maps,
  },
  data() {
    return {
      bidding: [],
      uni: 'all',
      fill: true,
      selectedGradient: gradients[4],
      gradients,
      padding: 0,
      loading: true,
      filterDialog: false,
      selectedUnit: null,
      unitOptions: [
        { text: 'Distribución', value: 'Distribución' },
        { text: 'Transmisión', value: 'Transmisión' },
        { text: 'Generación', value: 'Generación' },
      ],
      radius: 10,
      value: [0, 5, 6, 20, 70, 80],
      labels: [2022, 2023, 2024, 2025, 2026, 2027,],
      width: 10,
      points: [
        {
          "latitud": 14.119543275065197,
          "longitud": -87.20348376560112

        },
        {
          "latitud": 15.508250601133668,
          "longitud": -88.02032211811738

        },

        {
          "latitud": 14.467370426449762,
          "longitud": -87.63191003459949

        },

        {
          "latitud": 15.268162955055502,
          "longitud": -83.78571077364381

        },
      ],
      unidades: [
        {
          text: 'Distribución',
          value: 'Distribución'
        },
        {
          text: 'Transmisión',
          value: 'Transmisión'
        },
        {
          text: 'Generación',
          value: 'Generación'
        },
        {
          text: 'Todas',
          value: 'all'
        }
      ],
      selectProyectAll: [],

    }

  },
  computed: {
    sortPage() {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return this.bidding.sort(compare);
    },
    findProyects() {
      const proyectsLocal = Object.assign([], this.selectProyectAll);
      return proyectsLocal.filter(x => this.uni == 'all' ? x : this.uni == x.unidad)

    },
    mainCalcule() {
      const proyects = this.selectProyectAll.filter(x => this.uni === 'all' || this.uni === x.unidad);

      return proyects.reduce((acc, element) => {
        acc.presupuestoInicial += element.presupuestoInicial;
        acc.presupuestoInvertido += element.presupuestoInvertido;
        acc.productosSubcontratados += element.productosSubcontratados;
        acc.costoTotal += element.costoTotal;
        acc.familiasBeneficiarias += element.familiasBeneficiarias;
        acc.comunidadesBeneficiarias += element.comunidadesBeneficiarias;
        acc.empleosGenerados += element.empleosGenerados;
        return acc;
      }, {
        presupuestoInicial: 0,
        presupuestoInvertido: 0,
        productosSubcontratados: 0,
        costoTotal: 0,
        familiasBeneficiarias: 0,
        comunidadesBeneficiarias: 0,
        empleosGenerados: 0,
      });
    },

    departamentosAll() {
      const departamentosCount = this.selectProyectAll.reduce((acc, proyect) => {
        const departamento = proyect.ubicacionGeografica?.departamento?.nombre;
        if (departamento) {
          acc[departamento] = (acc[departamento] || 0) + 1;
        }
        return acc;
      }, {});

      return Object.entries(departamentosCount).map(([nombre, cantidad]) => ({
        nombre,
        cantidad
      }));
    },
    pointsProyects() {
      var points = [];
      for (let i = 0; i < this.selectProyectAll.length; i++) {
        const proyect = this.selectProyectAll[i];

        if (proyect.ubicacionGeografica.ubicacion.latitud && !proyect.vector) {

          var obj = {
            id: proyect.id,
            titulo: proyect.nombreCorto,
            descripcion: proyect.descripcion,
            portada: proyect.portada,
            unidad: proyect.unidad,
            ubicacion: proyect.ubicacionGeografica.ubicacion
          }
          points.push(obj)
        }

      }
      return points || []
    },
    polygonsProyects() {
      var Poligons = [];

      var proyectos = this.selectProyectAll.filter(x => x.vector && x.vector.tipo == "polygon")
      for (let i = 0; i < proyectos.length; i++) {
        const proyect = proyectos[i];


        var poli = {
          id: proyect.id,
          titulo: proyect.nombreCorto,
          descripcion: proyect.descripcion,
          portada: proyect.portada,
          unidad: proyect.unidad,
          coordenadas: proyect.ubicacionGeografica.ubicacion,
          vector: proyect.vector
        }
        Poligons.push(poli);
      }


      return Poligons || []
    },
    linesProyects() {
      var Lines = [];

      var proyectos = this.selectProyectAll.filter(x => x.vector && x.vector.tipo == "line")
      for (let i = 0; i < proyectos.length; i++) {
        const proyect = proyectos[i];


        var line = {
          id: proyect.id,
          titulo: proyect.nombreCorto,
          descripcion: proyect.descripcion,
          portada: proyect.portada,
          unidad: proyect.unidad,
          coordenadas: proyect.ubicacionGeografica.ubicacion,
          vector: proyect.vector
        }
        Lines.push(line);
      }


      return Lines || []
    },

  },

  filters: {
    currency(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
      });
      return `$ ${formatter.format(amount)}`;
    },
    formatterAmount(valor) {
      if (valor >= 1000000000) {
        return "L " + (valor / 1000000000.0).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) + " MM";
      }
      else if (valor >= 1000000) {
        return "L " + (valor / 1000000.0).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) + " M";
      }
      else if (valor >= 1000) {
        return "L " + (valor / 1000.0).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 }) + " K";
      }

      return "L " + valor;

    },
    currencySinL(amount) {
      const formatter = new Intl.NumberFormat("en-US");
      return `${formatter.format(amount)}`;
    },
  },
  methods: {
    applyFilter() {
      this.filterDialog = false;
      this.uni = this.selectedUnit;
    },
    ...mapActions(["setProyects_"]),
    unidadGet(e) {

      if (e == 'all') {
        this.selectProyectAll = Object.assign([], this.allProyectsLocal);
      } else {

        this.selectProyectAll = this.allProyectsLocal.filter(x => x.unidad == e);
        this.Unid = this.selectProyectAll[0].unidad
      }
      this.dialog = false;
    },
  },
  mounted() {
    this.loading = true;
    this.$nextTick(async () => {
      await axios
        .get(`${env.VUE_APP_LA_ENEE}/inversion-publica/api/v1/proyectos`, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Credentials": "true",
            Accept:
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
          },
        })
        .then((response) => {
          this.allProyectsLocal = response.data;
          this.selectProyectAll = Object.assign([], this.allProyectsLocal);
          this.loading = false;
        }).catch(error => {
          console.log(error);
          this.loading = false;
        });

    })
  }
}
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";

.card-rounded {
  border-radius: 30px !important;
}

.scrollable-content {
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 120px;
}

.investment-total {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
}

.investment-title {
  font-size: 20px;
  color: #616161 !important;
}

.investment-amount {
  color: #5FD0DF;
  line-height: 1.5em;
}

.investment-subtitle {
  line-height: 1em;
  font-size: 16px;
  margin-bottom: 5px;
}

.investment-description {
  font-weight: 200;
  margin-bottom: 0px;
}

.investment-icon {
  position: absolute !important;
  bottom: 10px;
  right: 20px;
}

.wrap-empleos {
  display: flex;
  gap: 16px;
  width: 100%;
  height: 100px;
}

.map-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none !important;
  cursor: pointer;
}

.map-content {
  border-radius: 30px;
  pointer-events: none !important;
}

.wrap-proyectos-list {
  height: calc(100% - 152px);
  overflow-y: scroll;

  @include responsive(mobile) {
    height: calc(30vh) !important;
  }
}

.text-imagen {
  position: absolute;
  bottom: 0px;
  color: #fff;
  width: 100%;
  height: auto;
  left: 0;
  background-color: rgba(0, 0, 0, 0.344);
}

.main-wrap-home {
  width: calc(100% - 20px);
  height: calc(100vh - 90px);
  margin-top: 70px;
  display: flex;
  gap: 16px;

  @include responsive(small) {
    flex-direction: column;
    height: auto;
    width: 100%;
    position: absolute;
    left: 0;
  }

  @include responsive(mobile) {
    flex-direction: column;
    height: auto;
    width: 100%;
    position: absolute;
    left: 0;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .wrap-l {
    width: 74%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include responsive(small) {
      width: 100%;
    }

    @include responsive(mobile) {
      width: 100%;
    }

    .card-t-l {
      height: 48%;
      width: 100%;
      display: flex;
      gap: 16px;

      @include responsive(small) {
        width: 100%;
        flex-direction: column;
      }

      @include responsive(mobile) {
        width: 100%;
        flex-direction: column;
        gap: 16px;
      }

      .avances {
        width: 50%;

        @include responsive(small) {
          width: 100%;
          height: 400px !important;
        }

        @include responsive(mobile) {
          width: 100%;
          height: 100% !important;
        }
      }

      .a-r {
        @include responsive(mobile) {
          height: 300px !important;
        }
      }

      .a-l {
        @include responsive(mobile) {
          height: 100% !important;
        }
      }
    }

    .card-b-l {
      height: 50%;
      width: 100%;
      display: flex;
      gap: 16px;

      @include responsive(small) {
        width: 100%;
      }

      @include responsive(mobile) {
        width: 100% !important;
        flex-direction: column;
        height: auto;
        gap: 16px;
      }

      .card {
        width: 28%;
        height: 100%;

        @include responsive(small) {
          width: 100%;
        }

        @include responsive(mobile) {
          width: 100%;
        }
      }
    }
  }

  .wrap-r {
    height: 100%;
    width: 26%;
    gap: 16px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    @include responsive(small) {
      width: 100%;
    }

    @include responsive(mobile) {
      width: 100%;
    }

    .card-list-calculate {
      height: 100%;
      width: 100%;
      display: flex;
      position: relative;
      flex-direction: column;
    }
  }
}

.leaflet-control-container {
  display: none;
}

.department-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-money {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-content: center;
  align-items: flex-start;
  height: 100px;
  width: 100%;
  padding-left: 20px;

  h2 {
    font-size: 2.1rem !important;
    line-height: 1em;

    @include responsive(mobile) {
      font-size: 2rem !important;
    }

    @include responsive(Ipad) {
      font-size: 1rem !important;
    }

    @include responsive(IpadPro) {
      font-size: 1.5rem !important;
    }
  }

  p {
    line-height: 10px;
    font-size: 14px;
    font-weight: 800;
  }
}

.mapa-container {
  width: calc(100% - 25%);
  height: 100%;

  @include responsive(mobile) {
    width: 100%;
    height: 300px;
  }
}

.linea-proyec {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  height: 70%;
}

.loading-home {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
</style>
